import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Axios from "axios";
import logo from "../../../assets/images/aurora-logo-boat.png";
import { FaPhone, FaTelegramPlane, FaTwitter } from "react-icons/fa";
import { GoLocation } from "react-icons/go";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      loading: false,
      sended: false,
    };
  }

  validateFields = () => {
    const { name, email, message } = this.state;
    return name !== "" && email !== "" && message !== "";
  };

  sendMessage = () => {
    const { name, email, message } = this.state;
    this.setState({ loading: true });

    if (name !== "" && email !== "" && message !== "") {
      Axios.post(
        "https://us-central1-rytmo-avrora.cloudfunctions.net/sendContactEmail",
        {
          name,
          email,
          message,
        }
      )
        .then((res) => {
          console.log("everything is OK");
          this.setState({ sended: true, loading: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
        });
    }
  };

  render() {
    const { t } = this.props;
    const { name, email, message, loading, sended } = this.state;
    return (
      <>
        <div className="container mx-auto mt-2 pt-12 pb-6">
          <h2 className="text-center text-4xl my-4 leading-tight">
            {t("home.contact.title")}
          </h2>
          <div className="text-center border border-gray-300 rounded-lg items-center p-4 pb-6 sm:p-12 m-4 mt-8">
            <div className="sm:flex">
              <div className="p-4 sm:block">
                <img
                  className="w-16 mx-auto"
                  src={logo}
                  alt={t("home.skills.captain.name")}
                />
              </div>
              <div className="flex-1 mt-4 sm:mt-0">
                <div className="sm:flex items-center mb-4">
                  <label className="sm:w-1/4 sm:text-right px-4 block text-gray-200 text-sm font-bold mb-2">
                    {t("home.contact.name.label")}
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={name}
                    onChange={(e) => this.setState({ name: e.target.value })}
                    type="text"
                    placeholder={t("home.contact.name.placeholder")}
                  />
                </div>
                <div className="sm:flex items-center mb-4">
                  <label className="sm:w-1/4 sm:text-right px-4 block text-gray-200 text-sm font-bold mb-2">
                    {t("home.contact.email.label")}
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    type="text"
                    placeholder={t("home.contact.email.placeholder")}
                  />
                </div>
              </div>
            </div>
            <label className="block text-gray-200 text-sm font-bold mb-2">
              {t("home.contact.message.label")}
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full h-24 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={message}
              onChange={(e) => this.setState({ message: e.target.value })}
              type="text"
              placeholder={t("home.contact.message.placeholder")}
            />
            {sended ? (
              <div className="text-green-300 mt-8">
                {t("home.contact.successMessage")}
              </div>
            ) : (
              <button
                className={`${
                  this.validateFields()
                    ? "bg-secondary hover:bg-gray-700"
                    : "bg-gray-500 cursor-default"
                } text-white font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline mt-8`}
                type="button"
                disabled={!this.validateFields() || loading}
                onClick={() => this.sendMessage()}
              >
                {loading ? (
                  <svg
                    className="animate-spin opacity-75 -ml-1 h-6 w-6"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>

                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  t("home.contact.send")
                )}
              </button>
            )}
          </div>
        </div>
        <div className="bg-secondary p-4">
          <div className="container mx-auto sm:flex items-center mt-2 py-12">
            <div className="flex items-center flex-1 justify-center mb-10 sm:mb-0">
              <div className="text-xl">
                <p className="flex items-center">
                  <FaPhone size={20} className="mx-4" />
                  (+34) 665 09 78 65
                </p>
                <p className="flex items-center">
                  <FaTelegramPlane size={20} className="mx-4" />
                  @avroraio
                </p>
                <p className="flex items-center">
                  <FaTwitter size={20} className="mx-4" />
                  @AvroraSoftware
                </p>
              </div>
            </div>
            <div className="flex items-center flex-1 justify-center">
              <GoLocation size={60} className="m-4" />
              <div className="">
                <p>{t("home.location.address.name")}</p>
                <p>{t("home.location.address.street")}</p>
                <p>
                  {t("home.location.address.code")}{" "}
                  {t("home.location.address.poblation")}
                </p>
                <p>{t("home.location.address.country")}</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(Contact);
