import React from "react";
import { useTranslation } from "react-i18next";
import {
  IoMdGlobe,
  IoIosPeople,
  IoIosSunny,
  IoIosCodeWorking,
  IoIosHappy,
  IoLogoApple,
  IoLogoAndroid,
  IoLogoHtml5,
  IoLogoCss3,
  IoIosDesktop,
  IoIosTabletPortrait,
  IoIosPhonePortrait,
} from "react-icons/io";
import "../Home.scss";

const What = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto mt-2 p-12">
      <h2 className="text-center text-4xl my-4 leading-tight">
        {t("home.services.title")}
      </h2>
      <div className="flex flex-col justify-center items-center md:flex-row-reverse">
        <div className="text-center px-4 py-2 m-2">
          <h2 className="text-2xl mb-2">{t("home.services.t1")}</h2>
          <p>{t("home.services.p1")}</p>
        </div>
        <div className="text-center p-2 m-2">
          <div className="flex">
            <IoMdGlobe className="icon-services" />
            <IoIosPeople className="icon-services" />
            <IoIosSunny className="icon-services" />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center md:flex-row">
        <div className="text-center px-4 py-2 m-2">
          <h2 className="text-2xl mb-2">{t("home.services.t2")}</h2>
          <p>{t("home.services.p2")}</p>
        </div>
        <div className="text-center p-2 m-2">
          <div className="flex">
            <IoIosCodeWorking size={20} />
            <IoIosCodeWorking size={40} />
            <IoIosCodeWorking size={60} />
            <IoIosCodeWorking size={80} />
            <IoIosHappy className="icon-services" />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center md:flex-row-reverse">
        <div className="text-center px-4 py-2 m-2">
          <h2 className="text-2xl mb-2">{t("home.services.t3")}</h2>
          <p>{t("home.services.p3")}</p>
        </div>
        <div className="text-center p-2 m-2">
          <div className="flex">
            <IoLogoApple className="icon-brand" />
            <IoLogoAndroid className="icon-brand" />
            <IoLogoHtml5 className="icon-brand" />
            <IoLogoCss3 className="icon-brand" />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center md:flex-row">
        <div className="text-center px-4 py-2 m-2">
          <h2 className="text-2xl mb-2">{t("home.services.t4")}</h2>
          <p>{t("home.services.p4")}</p>
        </div>
        <div className="text-center p-2 m-2">
          <div className="flex">
            <IoIosDesktop className="icon-services extra-mr" />
            <IoIosTabletPortrait className="icon-services" />
            <IoIosPhonePortrait className="icon-services" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default What;
