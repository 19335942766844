import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/aurora-logo-boat.png";
import i18next from "i18next";
import ReactCountryFlag from "react-country-flag";
import { IoIosArrowDown } from "react-icons/io";

const CookiesPolicy = () => {
  const { t } = useTranslation();

  const getLinesArr = () => {
    // const {t} = this.props;
    let arrResults = [];
    // for (let i = 0; i < t('cookies.totalLines') * 1; i++) {
    for (let i = 0; i < 37 * 1; i++) {
      arrResults.push(i);
    }
    return arrResults;
  };

  console.log("COOOOOOOKIES");

  return (
    <>
      <div className="fixed w-full z-20 border-gray-900 border-b">
        <nav className="flex items-center justify-between flex-wrap bg-secondary text-white py-4">
          <div className="flex items-center flex-shrink-0 pl-6 mr-6">
            <Link to="/" className="h-8 w-8 mr-2">
              <img src={logo} alt="Aurora Software" />
            </Link>
          </div>
          <div className="block md:hidden pr-6">
            <button
              className="flex items-center px-3 py-2 border rounded hover:bg-white hover:text-primary hover:border-primary"
              onClick={() => {}}
            >
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
        </nav>
      </div>
      <div className="p-12">
        <h2 className="text-2xl mt-12">{t("cookies.title")}</h2>

        {getLinesArr().map((item) => {
          if (
            t(`cookies.lines.${item}.title`) !==
            "cookies.lines." + item + ".title"
          ) {
            return (
              <h3
                key={"t" + item}
                className="text-lg font-bold"
                style={{ marginTop: "25px" }}
              >
                {t(`cookies.lines.${item}.title`)}
              </h3>
            );
          } else {
            if (
              t(`cookies.lines.${item}.subtitle`) !==
              "cookies.lines." + item + ".subtitle"
            ) {
              return (
                <h4
                  key={"t" + item}
                  className="font-semibold"
                  style={
                    t(`cookies.lines.${item - 1}.text`) !==
                    "cookies.lines." + (item - 1) + ".text"
                      ? { marginTop: "25px" }
                      : {}
                  }
                >
                  {t(`cookies.lines.${item}.subtitle`)}
                </h4>
              );
            } else {
              if (
                t(`cookies.lines.${item}.subsubtitle`) !==
                "cookies.lines." + item + ".subsubtitle"
              ) {
                return (
                  <h4
                    key={"t" + item}
                    className=""
                    style={
                      t(`cookies.lines.${item - 1}.text`) !==
                      "cookies.lines." + (item - 1) + ".text"
                        ? { marginTop: "25px" }
                        : {}
                    }
                  >
                    {t(`cookies.lines.${item}.subsubtitle`)}
                  </h4>
                );
              } else {
                return (
                  <p key={"p" + item}>{t(`cookies.lines.${item}.text`)}</p>
                );
              }
            }
          }
        })}
      </div>
    </>
  );
};

export default CookiesPolicy;
