import React from "react";
import { useTranslation } from "react-i18next";
import captain from "../../../assets/images/alvaro.jpeg";
import logo from "../../../assets/images/aurora-logo-boat.png";
import { IoLogoApple, IoLogoAndroid } from "react-icons/io";
import {
  FaDrupal,
  FaWordpressSimple,
  FaMagento,
  FaSymfony,
  FaPhp,
  FaCloud,
  FaDocker,
  FaNode,
  FaReact,
  FaAngular,
  FaVuejs,
  FaCss3,
  FaServer,
  FaGithubAlt,
  FaLinkedinIn,
  FaGrunt,
  FaGulp,
  FaLinux,
  FaHtml5,
  FaHeart,
} from "react-icons/fa";
import {
  DiFirebase,
  DiMongodb,
  DiMeteorfull,
  DiYii,
  DiJqueryLogo,
  DiDojo,
} from "react-icons/di";
import { GiShipWheel } from "react-icons/gi";

const Who = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto mt-2 py-12">
      <h2 className="text-center text-4xl my-4 leading-tight">
        {t("home.skills.title")}
      </h2>
      <div className="text-center flex flex-col items-center px-4 py-2 m-2">
        <p className="text-xl">{t("home.skills.captain.title")}</p>
        <div className="relative md:flex items-center border border-gray-300 px-6 py-8 m-2 my-4 rounded-lg shadow-lg text-center">
          <div className="mb-3 md:mb-0 relative">
            <img
              className="w-auto mx-auto rounded-full"
              src={captain}
              alt={t("home.skills.captain.name")}
            />
            <img
              className="h-10 w-10 absolute top-0 right-0 mr-10 mt-4 md:mr-0 md:mt-0 md:top-auto md:bottom-0 md:right-0"
              src={logo}
              alt="Aurora Software"
            />
          </div>
          <div className="px-5">
            <h2 className="text-2xl font-medium text-gray-200">
              {t("home.skills.captain.name")}
            </h2>
            <span className="text-gray-500 block mb-5 mt-2">
              {t("home.skills.captain.description")}
            </span>

            <div className="flex justify-center">
              <a
                href={t("home.skills.captain.linkedin")}
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col items-center p-2 hover:text-gray-500"
              >
                <FaLinkedinIn size={40} />
              </a>
              <a
                href={t("home.skills.captain.github")}
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col items-center p-2 hover:text-gray-500"
              >
                <FaGithubAlt size={40} />
              </a>
            </div>
          </div>
          <GiShipWheel size={22} className="absolute top-0 left-0 m-2" />
          <GiShipWheel size={22} className="absolute bottom-0 left-0 m-2" />
          <GiShipWheel size={22} className="absolute top-0 right-0 m-2" />
          <GiShipWheel size={22} className="absolute bottom-0 right-0 m-2" />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row justify-center items-stretch text-center">
        <div className="bg-gray-700 flex-1 flex flex-col-reverse sm:flex-col md:flex-row-reverse">
          <div className="flex-1 p-4 m-2">
            <h3 className="text-xl mb-3">{t("home.skills.frontend")}</h3>
            <div className="w-auto inline-block text-gray-300">
              <div className="flex items-center justify-center sm:justify-start">
                <FaReact size={20} />
                <p className="ml-2 my-1">{t("home.skills.react")}</p>
              </div>
              <div className="flex items-center justify-center sm:justify-start">
                <FaAngular size={20} />
                <p className="ml-2 my-1">{t("home.skills.angular")}</p>
              </div>
              <div className="flex items-center justify-center sm:justify-start">
                <FaVuejs size={20} />
                <p className="ml-2 my-1">{t("home.skills.vue")}</p>
              </div>
            </div>
          </div>
          <div className="flex-1 p-4 m-2">
            <h3 className="text-xl mb-3">{t("home.skills.backend")}</h3>
            <div className="w-auto inline-block text-gray-300">
              <div className="flex items-center justify-center sm:justify-start">
                <FaCloud size={20} />
                <p className="ml-2 my-1">{t("home.skills.cloud")}</p>
              </div>
              <div className="flex items-center justify-center sm:justify-start">
                <DiFirebase size={20} />
                <p className="ml-2 my-1">{t("home.skills.firebase")}</p>
              </div>
              <div className="flex items-center justify-center sm:justify-start">
                <FaDocker size={20} />
                <p className="ml-2 my-1">{t("home.skills.docker")}</p>
              </div>
              <div className="flex items-center justify-center sm:justify-start">
                <DiMeteorfull size={20} />
                <p className="ml-2 my-1">{t("home.skills.meteor")}</p>
              </div>
              <div className="flex items-center justify-center sm:justify-start">
                <FaNode size={20} />
                <p className="ml-2 my-1">{t("home.skills.node")}</p>
              </div>
              <div className="flex items-center justify-center sm:justify-start">
                <DiMongodb size={20} />
                <p className="ml-2 my-1">{t("home.skills.mongo")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-800 flex-1 flex flex-col md:flex-row">
          <div className="flex-1 p-4 m-2">
            <h3 className="text-xl mb-3">{t("home.skills.mobile")}</h3>
            <div className="w-auto inline-block text-gray-300">
              <div className="flex items-center justify-center sm:justify-start">
                <IoLogoApple size={20} />
                <p className="ml-2 my-1">{t("home.skills.ios")}</p>
              </div>
              <div className="flex items-center justify-center sm:justify-start">
                <IoLogoAndroid size={20} />
                <p className="ml-2 my-1">{t("home.skills.android")}</p>
              </div>
              <div className="flex items-center justify-center sm:justify-start">
                <FaReact size={20} />
                <p className="ml-2 my-1">{t("home.skills.reactNative")}</p>
              </div>
            </div>
          </div>
          <div className="flex-1 p-4 m-2">
            <h3 className="text-xl mb-3">{t("home.skills.php")}</h3>
            <div className="w-auto inline-block text-gray-300">
              <div className="flex items-center justify-center sm:justify-start">
                <FaSymfony size={20} />
                <p className="ml-2 my-1">{t("home.skills.symfony")}</p>
              </div>
              <div className="flex items-center justify-center sm:justify-start">
                <DiYii size={20} />
                <p className="ml-2 my-1">{t("home.skills.yii")}</p>
              </div>
              <div className="flex items-center justify-center sm:justify-start">
                <FaPhp size={20} />
                <p className="ml-2 my-1">{t("home.skills.cake")}</p>
              </div>
              <div className="flex items-center justify-center sm:justify-start">
                <FaWordpressSimple size={20} />
                <p className="ml-2 my-1">{t("home.skills.wordpress")}</p>
              </div>
              <div className="flex items-center justify-center sm:justify-start">
                <FaDrupal size={20} />
                <p className="ml-2 my-1">{t("home.skills.drupal")}</p>
              </div>
              <div className="flex items-center justify-center sm:justify-start">
                <FaMagento size={20} />
                <p className="ml-2 my-1">{t("home.skills.magento")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col text-center container mx-auto p-6">
        <h3 className="text-xl mb-3">{t("home.skills.tools")}</h3>
        <div className="grid sm:grid-cols-3 md:grid-cols-5 justify-center text-center text-gray-300">
          <div className="flex">
            <div className="mx-auto self-center flex items-center px-2">
              <FaCss3 size={20} />
              <p className="ml-2 my-1">{t("home.skills.tailwind")}</p>
            </div>
          </div>
          <div className="flex">
            <div className="mx-auto self-center flex items-center px-2">
              <FaCss3 size={20} />
              <p className="ml-2 my-1">{t("home.skills.bulma")}</p>
            </div>
          </div>
          <div className="flex">
            <div className="mx-auto self-center flex items-center px-2">
              <FaCss3 size={20} />
              <p className="ml-2 my-1">{t("home.skills.semantic")}</p>
            </div>
          </div>
          <div className="flex">
            <div className="mx-auto self-center flex items-center px-2">
              <FaCss3 size={20} />
              <p className="ml-2 my-1">{t("home.skills.bootstrap")}</p>
            </div>
          </div>
          <div className="flex">
            <div className="mx-auto self-center flex items-center px-2">
              <FaCss3 size={20} />
              <p className="ml-2 my-1">{t("home.skills.sass")}</p>
            </div>
          </div>
          <div className="flex">
            <div className="mx-auto self-center flex items-center px-2">
              <FaGrunt size={20} />
              <p className="ml-2 my-1">{t("home.skills.grunt")}</p>
            </div>
          </div>
          <div className="flex">
            <div className="mx-auto self-center flex items-center px-2">
              <FaGulp size={20} />
              <p className="ml-2 my-1">{t("home.skills.gulp")}</p>
            </div>
          </div>
          <div className="flex">
            <div className="mx-auto self-center flex items-center px-2">
              <DiJqueryLogo size={20} />
              <p className="ml-2 my-1">{t("home.skills.jquery")}</p>
            </div>
          </div>
          <div className="flex">
            <div className="mx-auto self-center flex items-center px-2">
              <DiDojo size={20} />
              <p className="ml-2 my-1">{t("home.skills.dojo")}</p>
            </div>
          </div>
          <div className="flex">
            <div className="mx-auto self-center flex items-center px-2">
              <FaHtml5 size={20} />
              <p className="ml-2 my-1">{t("home.skills.html5")}</p>
            </div>
          </div>
          <div className="flex">
            <div className="mx-auto self-center flex items-center px-2">
              <FaServer size={20} />
              <p className="ml-2 my-1">{t("home.skills.apache")}</p>
            </div>
          </div>
          <div className="flex">
            <div className="mx-auto self-center flex items-center px-2">
              <FaServer size={20} />
              <p className="ml-2 my-1">{t("home.skills.nginx")}</p>
            </div>
          </div>
          <div className="flex">
            <div className="mx-auto self-center flex items-center px-2">
              <FaGithubAlt size={20} />
              <p className="ml-2 my-1">{t("home.skills.git")}</p>
            </div>
          </div>
          <div className="flex">
            <div className="mx-auto self-center flex items-center px-2">
              <FaLinux size={20} />
              <p className="ml-2 my-1">{t("home.skills.linux")}</p>
            </div>
          </div>
          <div className="flex">
            <div className="mx-auto self-center flex items-center px-2">
              <p className="mr-2 my-1">{t("home.skills.love")}</p>
              <FaHeart size={20} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Who;
