import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import backend from "i18next-xhr-backend";
import languageDetector from "i18next-browser-languagedetector";

const LANGUAGES = ["es", "en"];
class Browser {
  constructor(services, options = {}) {
    this.type = "languageDetector";
    this.init(services, options);
  }

  init(services, options = {}, i18nOptions = {}) {
    this.detector = new languageDetector(services, options);
    this.i18nOptions = i18nOptions;
    this.detector.i18nOptions = i18nOptions;
  }

  detect(detectionOrder) {
    const found = this.detector.detect(detectionOrder).split("-")[0];
    console.log("detect");
    console.log(found);
    return LANGUAGES.includes(found) ? found : "en";
  }

  cacheUserLanguage(lng, caches) {
    this.detector.cacheUserLanguage(lng, caches);
  }
}

Browser.type = "languageDetector";

// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(Browser)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    ns: ["general"],
    defaultNS: "general",
    backend: {
      loadPath: process.env.REACT_APP_I18N_LOCALES,
    },
    fallbackLng: "en",
    debug: process.env.REACT_APP_I18N_DEBUG,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
    },
  });

export const config = () => {
  const instance = i18n.createInstance();

  instance
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(Browser)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      ns: ["general"],
      defaultNS: "general",
      backend: {
        loadPath: process.env.REACT_APP_I18N_LOCALES,
      },
      fallbackLng: process.env.REACT_APP_I18N_FALLBACK,
      debug: process.env.REACT_APP_I18N_DEBUG,

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    });
  return instance;
};

export default i18n;
