import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18next from "i18next";
import ReactCountryFlag from "react-country-flag";
import logo from "../../assets/images/aurora-logo-boat.png";
import { IoIosArrowDown } from "react-icons/io";
import What from "./components/What";
import Who from "./components/Who";
import Customers from "./components/Customers";
import Contact from "./components/Contact";
import "./Home.scss";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const Home = () => {
  const [menuHidden, setMenuHiden] = useState(true);
  const { t } = useTranslation();
  const initRef = useRef(null);
  const whatRef = useRef(null);
  const howRef = useRef(null);
  const customersRef = useRef(null);
  const contactRef = useRef(null);
  const executeScroll = (ref) => {
    scrollToRef(ref);
    if (!menuHidden) {
      toggleMenu();
    }
  };

  const LANGUAGES = {
    ES: "es",
    EN: "en",
  };

  const changeLanguage = (lng) => {
    i18next.changeLanguage(LANGUAGES[lng]);
  };

  const getLang = (lang) => {
    if (!lang) {
      return null;
    }
    let language;
    if (lang.includes("-")) {
      language = LANGUAGES[lang.split("-")[0]];
    } else {
      language = Object.keys(LANGUAGES).find((key) => LANGUAGES[key] === lang);
    }
    if (!language) {
      changeLanguage("EN");
      return "EN";
    }
    return language;
  };

  const toggleMenu = () => {
    setMenuHiden(!menuHidden);
  };

  const defaultLanguage = getLang(i18next.language);

  return (
    <>
      <div ref={initRef} className="fixed w-full z-20 border-gray-900 border-b">
        <nav className="flex items-center justify-between flex-wrap bg-secondary text-white py-4">
          <div className="flex items-center flex-shrink-0 pl-6 mr-6">
            <Link to="/">
              <button
                className="h-8 w-8 mr-2"
                onClick={() => executeScroll(initRef)}
              >
                <img src={logo} alt="Aurora Software" />
              </button>
            </Link>
          </div>
          <div className="block md:hidden pr-6">
            <button
              className="flex items-center px-3 py-2 border rounded hover:bg-white hover:text-primary hover:border-primary"
              onClick={() => toggleMenu()}
            >
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <div
            className={`w-full block flex-grow md:flex md:items-start md:w-auto ${
              menuHidden ? "hidden" : ""
            }`}
          >
            <div className="text-sm mt-4 pt-4 md:pt-0 px-5 md:mt-0 md:flex-grow md:text-right border-gray-900 border-t md:border-0">
              <button
                onClick={() => executeScroll(whatRef)}
                className="w-full md:w-auto block py-2 px-6 md:inline-block md:p-0 md:mr-4 hover:bg-white md:hover:bg-transparent hover:text-gray-500"
              >
                {t("home.menu.whatwedo")}
              </button>
              <button
                onClick={() => executeScroll(howRef)}
                className="w-full md:w-auto block py-2 px-6 md:inline-block md:p-0 md:mr-4 hover:bg-white md:hover:bg-transparent hover:text-gray-500"
              >
                {t("home.menu.team")}
              </button>
              <button
                onClick={() => executeScroll(customersRef)}
                className="w-full md:w-auto block py-2 px-6 md:inline-block md:p-0 md:mr-4 hover:bg-white md:hover:bg-transparent hover:text-gray-500"
              >
                {t("home.menu.clients")}
              </button>
              <button
                onClick={() => executeScroll(contactRef)}
                className="w-full md:w-auto block py-2 px-6 md:inline-block md:p-0 md:mr-4 hover:bg-white md:hover:bg-transparent hover:text-gray-500"
              >
                {t("home.menu.contact")}
              </button>
              <button
                onClick={() =>
                  defaultLanguage === "EN"
                    ? changeLanguage("ES")
                    : changeLanguage("EN")
                }
                className="w-full md:w-auto block py-2 px-6 md:inline-block md:p-0 md:mr-4 hover:bg-white md:hover:bg-transparent hover:text-gray-500"
              >
                <ReactCountryFlag
                  className="emojiFlag"
                  countryCode={defaultLanguage !== "EN" ? "GB" : "ES"}
                  style={{
                    fontSize: "1.5em",
                    lineHeight: "1em",
                  }}
                  aria-label={
                    defaultLanguage === "EN"
                      ? t("common.english")
                      : t("common.spanish")
                  }
                />
              </button>
            </div>
          </div>
        </nav>
      </div>
      <div className="pt-12">
        <div className="bg-primary text-white items-center text-center flex flex-col px-12 py-8">
          <div className="flex justify-center p-5">
            <img
              src={logo}
              alt="Aurora Software logo slider"
              className="w-32 md:w-48"
            />
          </div>
          <div className="text-2xl md:text-5xl">{t("home.welcome")}</div>
          <h1 className="text-3xl leading-tight uppercase md:text-6xl">
            {t("home.avrora")}
          </h1>
          <IoIosArrowDown
            size={50}
            className="mt-5 cursor-pointer hover:text-gray-500"
            onClick={() => executeScroll(whatRef)}
          />
        </div>
        <div ref={whatRef} className="pt-5 min-h-screen">
          <What />
        </div>
        <div ref={howRef} className="pt-5 min-h-screen bg-secondary text-white">
          <Who />
        </div>
        <div ref={customersRef} className="pt-5 min-h-screen">
          <Customers />
        </div>
        <div ref={contactRef} className="pt-5 bg-primary text-white ">
          <Contact />
        </div>
      </div>
    </>
  );
};

export default Home;
