import React from "react";
import { useTranslation } from "react-i18next";

import globatalent from "../../../assets/images/customers/gbt.png";
import mumablue from "../../../assets/images/customers/muma.png";
import salamandra from "../../../assets/images/customers/salamandra.png";
import atodocurso from "../../../assets/images/customers/atodocurso.png";
import quesoteca from "../../../assets/images/customers/quesoteca.png";
import monkschool from "../../../assets/images/customers/monk.png";
import raw from "../../../assets/images/customers/raw.png";
import magnolia from "../../../assets/images/customers/magnolia.png";
import psicin from "../../../assets/images/customers/psicin.png";

const Customer = (props) => {
  const customer = props.customer;
  return (
    <div className="px-2 pt-2 m-2">
      <div className="h-full items-center border border-gray-300 px-4 pt-6 pb-3 m-2 my-2 rounded-lg shadow-lg text-center">
        <div className="mb-3 h-16 flex items-center bg-gray-200 p-3">
          <img
            className="w-auto mx-auto max-h-full"
            src={customer.logo}
            alt={customer.name + " logo"}
          />
        </div>
        <div className="px-5">
          <h2 className="text-xl font-medium text-gray-800">{customer.name}</h2>
          <span className="text-gray-600 block mt-2">{customer.claim}</span>
        </div>
      </div>
    </div>
  );
};

const Customers = () => {
  const { t } = useTranslation();

  const customers = [
    {
      code: "gbt",
      name: "Globatalent",
      claim: "Sport neobank",
      logo: globatalent,
    },
    { code: "muma", name: "Mumablue", claim: "Books for kids", logo: mumablue },
    {
      code: "salamandra",
      name: "Salamandra",
      claim: "Rent spaces platform",
      logo: salamandra,
    },
    {
      code: "atodocurso",
      name: "A todo curso",
      claim: "Courses platform",
      logo: atodocurso,
    },
    {
      code: "quesoteca",
      name: "Quesoteca",
      claim: "Cheese online shop",
      logo: quesoteca,
    },
    {
      code: "monkschool",
      name: "MonkSchool",
      claim: "Music school",
      logo: monkschool,
    },
    { code: "raw", name: "Raw", claim: "Marketing agency", logo: raw },
    {
      code: "magnolia",
      name: "Magnolia catering",
      claim: "Wedding & Catering",
      logo: magnolia,
    },
    {
      code: "psicin",
      name: "Psicin",
      claim: "Integrative psychologist",
      logo: psicin,
    },
  ];

  return (
    <div className="container mx-auto mt-2 py-12">
      <h2 className="text-center text-4xl my-4 leading-tight">
        {t("home.customers.title")}
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 pb-6">
        {customers.map((item) => {
          return <Customer customer={item} key={item.code} />;
        })}
      </div>
    </div>
  );
};

export default Customers;
