import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./css/main.css";

import Header from "./components/common/header/Header";
import CookiesPolicy from "./components/common/legal/CookiesPolicy";
import Home from "./components/home/Home";
// import Rytmo from './components/rytmo/Rytmo'
import Footer from "./components/common/footer/Footer";
import CookieBanner from "react-cookie-banner";

function App() {
  const { t } = useTranslation();
  const styles = {
    banner: {
      height: "76px",
      display: "flex",
      alignItems: "center",
      fontSize: "15px",
      position: "fixed",
      bottom: 0,
    },
    button: {
      border: "1px solid white",
      borderRadius: 4,
      width: 76,
      height: 32,
      lineHeight: "32px",
      background: "transparent",
      color: "white",
      fontSize: "14px",
      fontWeight: 600,
      opacity: 1,
      right: 20,
      marginTop: -15,
    },
    message: {
      display: "block",
      padding: "10px",
      lineHeight: 1.3,
      textAlign: "left",
      marginRight: 86,
      color: "white",
    },
    link: {
      textDecoration: "none",
      fontWeight: "bold",
    },
  };
  return (
    <Router>
      <Header />
      <Switch>
        {/* <Route path="/rytmo">
          <Rytmo />
        </Route> */}
        <Route path="/cookies">
          <CookiesPolicy />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
      <CookieBanner
        message={t("common.cookies.message")}
        buttonMessage={t("common.cookies.accept")}
        link={
          <Link to="/cookies" style={{ marginLeft: 3 }}>
            {t("common.cookies.link")}
          </Link>
        }
        onAccept={() => {}}
        dismissOnScroll={false}
        styles={styles}
        cookie="user-has-accepted-cookies"
      />
      <Footer />
    </Router>
  );
}

export default App;
