import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <div className="bg-gray-200 text-center px-6 py-12">
        {currentYear} Aurora Software - All rigths reserved
      </div>
    </footer>
  );
};

export default Footer;
